import { ca } from 'date-fns/locale';
import { callGet, callPost, callPostUploadFileEbook, callPostWithParameterInBody } from '../helper/ApiHelper'
import moment from 'moment';
import Papa from 'papaparse';
var EbooksApiPrefixUrl = "";
var accessPath = "";

// if (process.env.REACT_APP_ENV === 'production') {
//     accessPath = "https://img.flixerapp.com";
//     EbooksApiPrefixUrl = 'https://ebookapi.flixerapp.com/V1';
// } else {
    accessPath = "https://imgdev.flixerapp.com";
    EbooksApiPrefixUrl = 'https://dev4.flixerapp.com/v1';
// }

const urlFileBannerImage = accessPath + '?action=img-upload2&dir=banner';
const urlEbookUploadCover = accessPath + '/ebookUploadCover';
const urlEbookUploadPdf = accessPath + '/ebookUploadPdf';
const urlEbookUploadProfile = accessPath + '/ebookUploadProfile';

export const capitalizeFirstLetter= (string) =>{
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

export const limitPageEbookDetails = 10; //กำหนดหน้า Page E-books Details

//คอลัม ค้นหา หนังสือ
export const listEbookSearchOptions = {
    // 0: { name: 'Book Title', columnName: 'book_title' },
    // 1: { name: 'Author Name', columnName: 'book_author' },
    // 2: { name: 'Category', columnName: 'name_en' },
    // 3: { name: 'Price', columnName: 'price' },
    // 4: { name: 'Status', columnName: 'status_name' }
    0: { name: 'Book Title', value: 'title' },
    1: { name: 'Author Name', value: 'author' },
    2: { name: 'Category', value: 'category' },
    3: { name: 'Price', value: 'price' },
    4: { name: 'Status', value: 'status' }
}

export const exportReportCSV  = (data) =>{
    let updatedArray = data.map(({ebook_id, book_title, book_type, isbn_number, isbn_ebook_number, book_author , total_income, ...rest }, index) => ({
        no: index + 1,
        book: book_title,
        "book type": book_type,
        "isbn":isbn_number,
        "isbn e-book": isbn_ebook_number,
        "author name": book_author,
        ...rest,
        "total income (฿)":total_income
    }));
    const csv = Papa.unparse(updatedArray);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'FinancialPayment.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

export function getCurrencyDisplay(value) {

    // let  displayValue = "฿" + value.toString();
    // if (value >= 1000) {
    let displayValue = "฿" + Number(value).toLocaleString(undefined, { maximumFractionDigits: 2 });
    // }
    return displayValue;
}

export function convertToNumberWith2Decimal(value) {
    return parseFloat(value).toFixed(2)
}

export function convertToStringWith2Decimal(value) {
    return Number(value).toLocaleString(undefined, { maximumFractionDigits: 2 });
}

export function isValidUrl(url) {
    // Regular expression pattern to match a valid URL
    const urlPattern = /^(https?:\/\/)(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\/[a-zA-Z0-9#-_=?&%/]*)?$/;

    // Test the URL against the regular expression pattern
    return urlPattern.test(url);
}

export default accessPath;
export const STATUS_PREPARE_FOR_SUBMISSION = 1; //Prepare for Submission ***
export const STATUS_WAITING_FOR_REVIEW = 2; //Waiting for Review // in admin dropdown **
export const STATUS_READY_FOR_SALE = 3; //Ready for Sale ***
export const STATUS_REJECTED = 4; //Rejected // in admin dropdown **
export const STATUS_IN_REVIEW = 5; //In Review // in admin dropdown **
export const STATUS_PENDING_RELEASE = 6; //Pending Release ***
export const STATUS_PUBLISHER_REJECTED = 8; //PUBLISHER_REJECTED *** publisher กด reject ก่อนจะ review ในหน้า view book
export const STATUS_REMOVED_FROM_SALE = 9; //Removed from Sale *** reject จาก tab ready for sale ใน admin
export const STATUS_PUBLISHER_REMOVE_FROM_SALE = 10; //Publisher Removed from Sale *** reject จาก publsher ใน viewbook 


export async function uploadFileBannerImage(formData) {
    return callPostUploadFileEbook(urlFileBannerImage , formData);
}

export function localeString(date) {
    const dateString = new Date(date);
    let formattedDate = dateString.toLocaleString('th-TH', {
        day: '2-digit',
        month: '2-digit'
    });

    formattedDate += '/' + dateString.getFullYear();


    // เพิ่มเงื่อนไขเพื่อตรวจสอบว่ามีข้อมูลเวลามาด้วยหรือไม่
    if (dateString.getHours() !== 0 || dateString.getMinutes() !== 0) {
        formattedDate += ' ' + dateString.toLocaleTimeString('th-TH', {
            hour: '2-digit',
            minute: '2-digit'
        });

    }

    return formattedDate;
}

export function getDisplayDate(dateString, format){
    return moment(localeString(dateString), 'DD/MM/YYYY HH:mm').format(format)
}

export function getDisplayDateShortFormat(dateString){
    return new Date(dateString).toLocaleString('en-us', { month: 'short', year: 'numeric' })
}

export function getPDF(publisher, ebookId, type) {
    //imgdev.flixerapp.com/ebookToken/{publisher_id}/{ebook_id}/{pdf,sample,watermark}
    let pdfurl = accessPath + '/ebookToken/' + publisher + '/' + ebookId + '/' + type;
    //console.log(pdfurl);
    return callGet(pdfurl, {
    })
}


export function getPublisher() {
    return callGet(EbooksApiPrefixUrl + '/getPublisher', {
    })
}

export async function updateEbookPDFPath(uploadedMainPdfPath = '', uploadedSamplePdfPath = '', ebookId) {
    return await callPost(EbooksApiPrefixUrl + '/updateEbookPDFPath', {
        uploadedMainPdfPath: uploadedMainPdfPath,
        uploadedSamplePdfPath: uploadedSamplePdfPath,
        ebookId: ebookId
    });
}

export async function updateEbookCoverPath(uploadCoverImagePath = '', ebookId) {
    return await callPost(EbooksApiPrefixUrl + '/uploadCoverImagePath', {
        uploadCoverImagePath: uploadCoverImagePath,
        ebookId: ebookId
    });
}

export function getUser() {
    return callGet(EbooksApiPrefixUrl + '/getUser', {
    })
}


export function paymentData() {
    return callGet(EbooksApiPrefixUrl + '/paymentData', {
    })
}


export function viewFinancial(periodDat) {
    return callGet(EbooksApiPrefixUrl + '/viewFinancial?periodDate=' + periodDat, {
    })
}

// export function getReport(formattedDates, platform, selectedOption) {
//     return callGet(EbooksApiPrefixUrl + '/getReport?formattedDates=' + formattedDates + '&platform=' + platform + '&selectedOption=' + selectedOption, {
//     })
// }

export function getReportData(formattedDates, platform, selectedOption, saleType) {
    return callPost(EbooksApiPrefixUrl + '/getReportData', {
        // startDate: "2023-07-10 00:00:00",
        // endDate: "2023-07-13 00:00:00",
        startDate: formattedDates[0],
        endDate: formattedDates[1],
        platform: platform,
        option: selectedOption,
        viewBy: 'overview',
        saleType: saleType
    })
}

export function getAvailableReportDate(maxDate, startDate, endDate, option) {
    return callGet(EbooksApiPrefixUrl + '/getAvailableReportDate?maxDate=' + maxDate + '&startDate=' + startDate + '&endDate=' + endDate + '&option=' + option, {
    })
}

export function getAccumulatedSales(maxDate) {
    return callGet(EbooksApiPrefixUrl + '/getAccumulatedSales?maxDate=' + maxDate, {
    })
}

export async function getChartDataTableJSON(reportByDate, ebooksLookUp, option, viewBy, chartType) {
    return callPostWithParameterInBody(EbooksApiPrefixUrl + '/getChartDataTableJSON', {
        reportByDate: reportByDate,
        ebooksLookUp: ebooksLookUp,
        option: option,
        viewBy: viewBy,
        chartType: chartType
    })
}

export function wearRights(email) {
    return callPost(EbooksApiPrefixUrl + '/wearRights', {
        email: email
    })
}

export function getErrorBoundary() {
    return callGet(EbooksApiPrefixUrl + '/ErrorBoundary', {
    })
}


export function getProfile() {
    return callGet(EbooksApiPrefixUrl + '/getProfile', {
    })
}


export function getHistoryEbook(ebookId) {
    return callGet(EbooksApiPrefixUrl + '/getHistoryEbook?ebookId=' + ebookId, {
    })
}

export async function uploadFileEbook(formData, ebookId, publishID) {
    return callPostUploadFileEbook(urlEbookUploadCover + '/' + publishID + '/' + ebookId, formData, EbooksApiPrefixUrl);
}

export async function uploadFileCompany(formData, publishID = "") {
    return callPostUploadFileEbook(urlEbookUploadProfile + '/' + publishID + '/id', formData, EbooksApiPrefixUrl);
}

export async function uploadPdfEbook(formDataPdfEbook, ebookId, publishID) {
    return callPostUploadFileEbook(urlEbookUploadPdf + '/' + publishID + '/' + ebookId, formDataPdfEbook, EbooksApiPrefixUrl);
}

export async function updateCompanyCertFilePath(companyCertFilePath = '') {

    return callPost(EbooksApiPrefixUrl + '/updateCompanyCertFilePath', {
        companyCertFilePath: companyCertFilePath
    });
}

export async function updateCompanyCertFilePathWithPublisherId(companyCertFilePath = '', publisherId) {

    return callPost(EbooksApiPrefixUrl + '/updateCompanyCertFilePathWithPublisherId', {
        companyCertFilePath: companyCertFilePath,
        publisherId: publisherId
    });
}

export function getPublisherDetail() {
    return callGet(EbooksApiPrefixUrl + '/getPublisherDetail', {
    })
}


//จังหวัด
export function getProvince() {
    return callGet(EbooksApiPrefixUrl + '/getProvince', {
    })
}

//ธนาคาร
export function getBank() {
    return callGet(EbooksApiPrefixUrl + '/getBank', {
    })
}

//ธนาคาร
export function getBankAccount() {
    return callGet(EbooksApiPrefixUrl + '/getBankAccount', {
    })
}



export function getEbookPrice() {
    return callGet(EbooksApiPrefixUrl + '/getEbookPrice', {
    })
}



export function getEbookCategory() {
    return callGet(EbooksApiPrefixUrl + '/getEbookCategory', {
    })
}

//admin

export function searchAllEbookDetails(keyword, page, limitPage) {
    return callGet(EbooksApiPrefixUrl + '/searchAllEbookDetails?keyword=' + keyword + '&page=' + page + '&limitPage=' + limitPage, {
    });
}
export function getAllEbookDetails(page, limitPage) {
    return callGet(EbooksApiPrefixUrl + '/getAllEbookDetails?page=' + page + '&limitPage=' + limitPage, {
    })
}


export function apiSearchEbookDetails(keyword, option, page, limitPage) {
    return callGet(EbooksApiPrefixUrl + '/searchEbookDetails?keyword=' + keyword + '&page=' + page + '&limitPage=' + limitPage + '&option=' + option, {
    });
}
export function getEbookDetails(page, limitPage) {
    return callGet(EbooksApiPrefixUrl + '/getEbookDetails?page=' + page + '&limitPage=' + limitPage, {
    })
}



export function getCategories() {
    return callGet(EbooksApiPrefixUrl + '/getCategories', {
    })
}

export function searchCategories(keyword, columnName) {
    return callGet(EbooksApiPrefixUrl + '/searchCategories?keyword=' + keyword + '&columnName=' + columnName, {
    })
}




export function itemCategory(categoriesId = "") {
    return callGet(EbooksApiPrefixUrl + '/itemCategory?categoriesId=' + categoriesId, {
    })
}


export function resetPassword(currentPassword, newPassword) {
    return callPost(EbooksApiPrefixUrl + '/resetPassword', {
        currentPassword: currentPassword,
        newPassword: newPassword
    });
}

export function insertCategoriesDetails(categoryNameEng, categoryNameThai, status, displayOrder) {
    return callPost(EbooksApiPrefixUrl + '/insertCategoriesDetails', {
        categoryNameEng: categoryNameEng,
        categoryNameThai: categoryNameThai,
        status: status,
        displayOrder: displayOrder
    });

}

export function editCategoriesDetails(categoriesId, categoryNameEng, categoryNameThai, status, displayOrder) {
    return callPost(EbooksApiPrefixUrl + '/editCategoriesDetails', {
        categoriesId: categoriesId,
        categoryNameEng: categoryNameEng,
        categoryNameThai: categoryNameThai,
        status: status,
        displayOrder: displayOrder
    });

}

export function getApproveStatus() {
    return callGet(EbooksApiPrefixUrl + '/getApproveStatus', {
    })
}

export function getEbookURL(ebookId){
    return callGet(EbooksApiPrefixUrl + "/getEbookURL/" + ebookId,{});
}


///////////

export async function updateEbookApproveStatusDetails(ebookDetailsID, approveStatusId, notes = "") {
    return callPost(EbooksApiPrefixUrl + '/updateEbookApproveStatusDetails', {
        ebookDetailsID: ebookDetailsID,
        approveStatusId: approveStatusId,
        notes: notes

    });
}





export function getPublisherTag() {
    return callGet(EbooksApiPrefixUrl + '/getPublisherTag', {
    })
}


export function getPublisherKeyword() {
    return callGet(EbooksApiPrefixUrl + '/getPublisherKeyword', {
    })
}

export function getEbookSerie() {
    return callGet(EbooksApiPrefixUrl + '/getEbookSerie', {
    })
}


export function getEbookItems(EbookDetailsID = '') {
    return callGet(EbooksApiPrefixUrl + '/getEbookItems?ebookDetailsID=' + EbookDetailsID, {
    })
}


export function deleteeEbookDetails(ebookDetailsID) {

    return callGet(EbooksApiPrefixUrl + '/deleteEbookDetails?ebookDetailsID=' + ebookDetailsID, {

    })
}

export function requestWithholdingTax(selectedYear, selectedMonths, toEmail) {
    return callPost(EbooksApiPrefixUrl + '/requestWithholdingTax', {
        email: toEmail,
        year: selectedYear,
        months: selectedMonths
    })
}



export async function editBankAccount(bankID, accountName, accountNumber, accountType, branch, provincebank) {
    return callPost(EbooksApiPrefixUrl + '/editBankAccount', {
        bankID: bankID,
        accountName: accountName,
        accountNumber: accountNumber,
        accountType: accountType,
        branch: branch,
        provincebank: provincebank
    })
}



export async function emailLogin(username, password) {
    return callPost(EbooksApiPrefixUrl + '/emailLogin', {
        email: username,
        password: password,
        devicie_id: 'ebook-cms',
        screen_density: 'hdpi',
        mac_address: ''
    })
}

export async function forgetPassword(email) {
    return callPost(EbooksApiPrefixUrl + "/forgotPassword", {
        email: email
    })
}

export async function sendVerifyAccountEmailWithoutLogin(verifyEmailToken) {
    return callPost(EbooksApiPrefixUrl + "/sendVerifyAccountEmailWithoutLogin", {
        verifyToken: verifyEmailToken
    });
}

export async function checkWebCredentialExist() {
    return callGet(EbooksApiPrefixUrl + '/checkWebCredentialExist')
}

export async function logout() {
    return callPost(EbooksApiPrefixUrl + '/logout', {})
}

export function emailSignUp(username, password) {
    return callPost(EbooksApiPrefixUrl + '/register', {
        email: username,
        password: password
    })
}

export function updatePublisherDetail(operationMode,
    CorporationName = "", taxId = "", selectedAddress = "", personalCorporation = "", penNamePublisher = "", nameSurname = "", idCard = "", phoneNumber = "",
    address = "", street = "", subdisTrict = "", disTrict = "", province = "", zipCode = "",
    addressDocment = "", streetDocment = "", subdistrictDocment = "", districtDocment = "", provinceDocment = "", zipCodeDocment = "",
    bank = "", accountName = "", accountNumber = "", accountType = "", branch = "", provincebank = "", accountingContactName = "", accountingPhoneNumber = "", accountingEmail = "",
    email = "", password = "") {

    var apiName = "/insertPublisherDetail";

    if (operationMode == "update") {
        apiName = "/updatePublisherDetail";
    }

    return callPost(EbooksApiPrefixUrl + apiName, {
        CorporationName: CorporationName,
        taxId: taxId,
        personalCorporation: personalCorporation,
        penNamePublisher: penNamePublisher,
        nameSurname: nameSurname,
        idCard: idCard,
        phoneNumber: phoneNumber,
        address: address,
        street: street,
        subdisTrict: subdisTrict,
        disTrict: disTrict,
        province: province,
        zipCode: zipCode,
        selectedAddress: selectedAddress,
        addressDocment: addressDocment,
        streetDocment: streetDocment,
        subdistrictDocment: subdistrictDocment,
        districtDocment: districtDocment,
        provinceDocment: provinceDocment,
        zipCodeDocment: zipCodeDocment,
        bank: bank,
        accountName: accountName,
        accountNumber: accountNumber,
        accountType: accountType,
        branch: branch,
        provincebank: provincebank,
        accountingContactName: accountingContactName,
        accountingPhoneNumber: accountingPhoneNumber,
        accountingEmail: accountingEmail,
        email: email,
        password: password

    });
}


export function editEbookDetails(
    uploadEBook = "", bookTitle = "", authorName = "", category = "", otherSeries = "", otherSeriesName = "", selectedOnlyOver = "", synopsis = "", bookType = "", isbn = "", isbnEbook = "", tags = "", Keywords = "",
    PricingAndSales = "", paperCoverPrice = "", paperBookPrice = "",
    publishOption = "", publicDate = "", noteTeam = "", ebookDetailsID = "", volume = "" , rightToLeft = ""
) {

    return callPost(EbooksApiPrefixUrl + '/editEbookDetails', {
        uploadEBook: uploadEBook,
        bookTitle: bookTitle,
        authorName: authorName,
        category: category,
        otherSeries: otherSeries,
        otherSeriesName: otherSeriesName,
        selectedOnlyOver: selectedOnlyOver,
        synopsis: synopsis,
        bookType: bookType,
        isbn: isbn,
        isbnEbook: isbnEbook,
        tags: tags,
        Keywords: Keywords,

        PricingAndSales: PricingAndSales,
        paperCoverPrice: paperCoverPrice,
        paperBookPrice: paperBookPrice,


        publishOption: publishOption,
        publicDate: publicDate,
        noteTeam: noteTeam,
        ebookDetailsID: ebookDetailsID,
        volume: volume , 
        rightToLeft: rightToLeft
    });
}


export function insertEbookDetails(uploadEBook = '', bookTitle = '', authorName = '', category = '', otherSeries = '', otherSeriesName = '', selectedOnlyOver = '', synopsis = '', bookType = '', isbn, isbnEbook = '', tags = '', Keywords = '',
    PricingAndSales = '', paperCoverPrice = '', paperBookPrice = '',
    publishOption = '', publicDate = "", noteTeam = '', volume = '' , rightToLeft = '') {
    return callPost(EbooksApiPrefixUrl + '/insertEbookDetails', {
        uploadEBook: uploadEBook,
        bookTitle: bookTitle,
        authorName: authorName,
        category: category,
        otherSeries: otherSeries,
        otherSeriesName: otherSeriesName,
        selectedOnlyOver: selectedOnlyOver,
        synopsis: synopsis,
        bookType: bookType,
        isbn: isbn,
        isbnEbook: isbnEbook,
        tags: tags,
        Keywords: Keywords,
        PricingAndSales: PricingAndSales,
        paperCoverPrice: paperCoverPrice,
        paperBookPrice: paperBookPrice,
        publishOption: publishOption,
        publicDate: publicDate,
        noteTeam: noteTeam,
        volume: volume ,
        rightToLeft: rightToLeft
    });

}




export function insertBanner(refreferenceName, imageUrl, status, publishDate, unPublishDete, fileBannerImage, bannerType, selectedFileCoverImage) {
    return callPost(EbooksApiPrefixUrl + '/insertBanner', {

        refreferenceName: refreferenceName,
        imageUrl: imageUrl,
        status: status,
        publishDate: publishDate,
        unPublishDete: unPublishDete,
        fileBannerImage: fileBannerImage,
        bannerType: bannerType,
        file: selectedFileCoverImage

    })
}


export function editBannerDetails(refreferenceName, imageUrl, status, publishDate, unPublishDete, fileBannerImage, bannerType, selectedFileCoverImage, bannerId) {
    return callPost(EbooksApiPrefixUrl + '/editBannerDetails', {

        refreferenceName: refreferenceName,
        imageUrl: imageUrl,
        status: status,
        publishDate: publishDate,
        unPublishDete: unPublishDete,
        fileBannerImage: fileBannerImage,
        bannerType: bannerType,
        file: selectedFileCoverImage , 
        bannerId: bannerId

    })
}

export function updateImgBannerUrl(tfile = '' , bannerId) {
    return callGet(EbooksApiPrefixUrl + '/updateImgBannerUrl?imgBannerUrl=' + tfile+'&bannerId='+bannerId, {
    })
}

export function getBannerDetails(bannerId = '') {
    return callGet(EbooksApiPrefixUrl + '/getBannerDetails?bannerId=' + bannerId, {
    })
}


export function getAutocompleteEbooks(titleName , id = '') {
    return callGet(EbooksApiPrefixUrl + '/getAutocompleteEbooks?titleName='+titleName+'&id='+id, {
    })
}


export function deleteBanner(bannerId = '') {
    return callGet(EbooksApiPrefixUrl + '/deleteBanner?bannerId=' + bannerId, {
    })
}

export function rowReorderBannerDetails(bannerIds = ''){
    return callGet(EbooksApiPrefixUrl + '/rowReorderBannerDetails?bannerIds=' + bannerIds, {
    })
}

export function searchBannerDetails(keyword , columnName = '') {
    return callGet(EbooksApiPrefixUrl + '/searchBannerDetails?keyword='+keyword+'&columnName='+columnName, {
    })
}



export function getShelfDetails(shelfId = '' , pagesTabId = '') {
    return callGet(EbooksApiPrefixUrl + '/getShelfDetails?shelfId=' + shelfId+'&pagesTabId='+pagesTabId, {
    })
}


export function rowReorderShelfDetails(shelfId = '', pagesTabId = ''){
    return callGet(EbooksApiPrefixUrl + '/rowReorderShelfDetails?shelfIds=' + shelfId +'&pagesTabId='+pagesTabId, {
    })
}

//
export function getAutocompleteShelf(displayType = '') {
    return callGet(EbooksApiPrefixUrl + '/getAutocompleteShelf?displayType=' + displayType, {
    })
}


export function insertShelfDetails(shelfTitle, displayType, contents, status , pagesTabId) {
    return callPost(EbooksApiPrefixUrl + '/insertShelfDetails', {
        shelfTitle: shelfTitle,
        displayType: displayType,
        contents: contents,
        status: status ,
        pagesTabId: pagesTabId

    })
}
export function editShelfDetails(shelfTitle, displayType, contents, status , shelfId) {
    return callPost(EbooksApiPrefixUrl + '/editShelfDetails', {
        shelfTitle: shelfTitle,
        displayType: displayType,
        contents: contents,
        status: status,
        shelfId: shelfId

    })
}

export function deleteShelf(shelfId = '') {
    return callGet(EbooksApiPrefixUrl + '/deleteShelf?shelfId=' + shelfId, {
    })
}


export function getShelfItem(displayType , contentsId = '') {
    return callGet(EbooksApiPrefixUrl + '/getShelfItem?displayType='+displayType+'&contentsId='+contentsId, {
    })
}






export function getPagesSelf(pagesTabId = '') {
    return callGet(EbooksApiPrefixUrl + '/getPagesSelf?pagesTabId=' + pagesTabId, {
    })
}


export function rowReorderPagesShelfDetails(pagesShelfIds = ''){
    return callGet(EbooksApiPrefixUrl + '/rowReorderPagesShelfDetails?pagesShelfIds=' + pagesShelfIds, {
    })
}

export function deletePagesShelf(pagesShelfId = '') {
    return callGet(EbooksApiPrefixUrl + '/deletePagesShelf?pagesShelfId=' + pagesShelfId, {
    })
}

export function duplicatePagesShelf(pagesShelfId = '') {
    return callGet(EbooksApiPrefixUrl + '/duplicatePagesShelf?pagesShelfId=' + pagesShelfId, {
    })
}

export function insertPagesShelf(tabName, status) {
    return callPost(EbooksApiPrefixUrl + '/insertPagesShelf', {
        tabName: tabName,
        status: status

    })
}

export function editPagesShelf(tabName, status  ,pagesTabId ) {
    return callPost(EbooksApiPrefixUrl + '/editPagesShelf', {
        tabName: tabName,
        status: status , 
        pagesTabId: pagesTabId

    })
}


export function ItemPagesShelf(keyword = '' , columnName = '') {
    return callGet(EbooksApiPrefixUrl + '/ItemPagesShelf?keyword=' + keyword +'&columnName='+columnName, {
    })
}

export function ItemShelf(keyword = '' , columnName = '' , pagesTabId = '') {
    return callGet(EbooksApiPrefixUrl + '/ItemShelf?keyword=' + keyword +'&columnName='+columnName+'&pagesTabId='+pagesTabId, {
    })
}



export function getManageReview(pageReview = 1 ,limitPage) {
    return callGet(EbooksApiPrefixUrl + '/getManageReview?page='+pageReview+'&limitPage='+limitPage, {
    })
}

export function searchManageReview(page = 1, keyword = '', option = '' , limitPage) {
    return callGet(EbooksApiPrefixUrl + '/searchManageReview?page='+page+'&keyword='+keyword+'&option='+option+'&limitPage='+limitPage, {
    })
}

export function deleteManageReview(id = '') {
    return callGet(EbooksApiPrefixUrl + '/deleteManageReview?id=' + id, {
    })
}






export function getCustomerServiceAccount(customerServiceAccount) {
    return callGet(EbooksApiPrefixUrl + '/getCustomerServiceAccount?customerServiceAccount=' + customerServiceAccount, {
    })
}

export function getCustomerServiceReceiptNo(ReceiptNo) {
    return callGet(EbooksApiPrefixUrl + '/getCustomerServiceReceiptNo?customerServiceReceiptNo=' + ReceiptNo, {
    })
}


export async function checkImportEbooks(file) {
    return callPostUploadFileEbook(EbooksApiPrefixUrl + '/checkImportEbooks', file);
}

export async function importEbooks(file) {
    return callPostUploadFileEbook(EbooksApiPrefixUrl + '/importEbooks', file);
}






