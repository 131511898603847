import React from 'react';
import { Row, Col, InputGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Head from '../../components/navbar/Head.js';
import NavBar from '../../components/navbar/NavBar.js';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { paymentData, viewFinancial, convertToNumberWith2Decimal,convertToStringWith2Decimal,getDisplayDate,getDisplayDateShortFormat } from '../../api/index.js'
import { useState, useEffect } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { checkUserCanViewPage, isPublisherLevel, isUserLogedIn } from '../../helper/Helper.js';
import { exportReportCSV,getCurrencyDisplay, localeString, getProvince, getBank, editBankAccount, getBankAccount, getPublisherDetail, requestWithholdingTax } from '../../api'
import { isNumeric, isLength } from 'validator';
import Papa from 'papaparse';
import { hideLoading, showLoading } from '../../helper/Helper.js';
import { dialogConfirmSetData, dialogConfirmSetShowModal } from '../../actions/confirmDialog.action.js';

const Financial = () => {
    const dispatch = useDispatch();

    const [formData, setFormData] = useState('');
    const [publisherBankAccount, setpublisherBankAccount] = useState([]);
    const [idConfirmError, setIdConfirmError] = useState('');
    const [phoneNumberError, setphoneNumberError] = useState('');
    const [accountNumberError, setaccountNumberError] = useState('');
    const [optionsProvince3, setOptionsProvince3] = useState([]);
    const [validated, setValidated] = useState(false);
    const [AlertError, setAlertErrorError] = useState('');
    const [userEmail, setUserEmail] = useState('');

    const userProfile = useSelector(state => state.user.profile) || "";
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 20 }, (_, index) => currentYear - index);
    const [activeKey, setActiveKey] = useState('home');
    const [datagetPayment, setDataPayment] = useState([]);
    const navigate = useNavigate();
    const [selectedMonths, setSelectedMonths] = useState([]);
    const currentMonth = moment().month() + 1; // +1 เพราะ moment.js เริ่มนับเดือนที่ 0
    const [withHoldingTaxYear, setWithHoldingTaxYear] = useState(currentYear);
    const handleChangeWithHoldingTaxYear = (event) => {
        setWithHoldingTaxYear(event.target.value);
    
        const filteredArray = selectedMonths.filter(item => {
            // Check if the item meets your condition
            if (item > currentMonth && currentYear == event.target.value) {
                // If the condition is met, return false to remove the item from the array
                return false;
            }
            // If the condition is not met, return true to keep the item in the array
            return true;
        });

        setSelectedMonths(filteredArray);
    }

    useEffect(() => {
        if (userProfile !== null) {
            setUserEmail(userProfile.email)
        }
    }, [userProfile]);

    //get ธนาคาร getBank
    const [optionsBank, setOptionsBank] = useState([]);
    function showBank() {
        getBank().then(handleShowBankResponse)
    }
    const handleShowBankResponse = async (response) => {
        setOptionsBank(response.data);
    }
    //===========================================================//

    //get ธนาคาร getBankAccount
    const [optionsgetBankAccount, setOptionsgetBankAccount] = useState([]);
    function showgetBankAccount() {
        getBankAccount().then(handleShowgetBankAccountResponse)
    }
    const handleShowgetBankAccountResponse = async (response) => {
        setOptionsgetBankAccount(response.data);
    }

    //get จังหวัด
    function showProvince() {
        getProvince().then(handleShowProvinceResponse)
    }
    const handleShowProvinceResponse = async (response) => {
        setOptionsProvince3(response.data);
    }

    //get ข้อมูลประเทศ
    function showgetPublisherDetail() {
        getPublisherDetail().then(handleShowgetPublisherDetailResponse)
    }
    const handleShowgetPublisherDetailResponse = async (response) => {
        setpublisherBankAccount(response.data['publisherBankAccount']);
        // console.log(response.data['publisherBankAccount']);
    }

    const handleSubmitWithHoldingRequest = (e) => {
        e.preventDefault();
        
        if(selectedMonths.length > 0){

            const sortedMonths = selectedMonths.sort((a, b) => a - b);
            let selectedMonthsString = sortedMonths.join();
        
            showLoading();

            requestWithholdingTax(withHoldingTaxYear, selectedMonthsString, userEmail).then((response) => {
                hideLoading();
    
                let title = response.data["message"]["title"];
                let message = response.data["message"]["message"];
                let button = response.data["message"]["button"];
                showAlertDialog(title, message, button, null);

                resetWithholdingTaxForm();
                
            })
        }else{
            let title = "Error";
            let message = "Please select request months"
            let button = "OK"
            showAlertDialog(title, message, button, null);
        }
        
    }

    function resetWithholdingTaxForm(){
        setSelectedMonths(new Array())
        setWithHoldingTaxYear(currentYear)
    }

    function showAlertDialog(title, message, button, okAction){

        if(button == null){
          button = "OK";
        }
    
        dispatch(dialogConfirmSetData({
          title: title,
          message: message,
          closeButton: null,
          confirmButton: button,
          eventConfirmButton:okAction,
          eventCloseButton: null,
          showCloseButton: false
        }));
    
        // ** ทำให้ dialog โชว์ขึ้นมา **
        dispatch(dialogConfirmSetShowModal(true));
      }

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        const bankID = formData.bank || publisherBankAccount['bank_id'];;
        const accountName = formData.accountName || publisherBankAccount['account_name'];
        const accountNumber = formData.accountNumber || publisherBankAccount['account_number'];
        const accountType = formData.accountType || publisherBankAccount['account_type_id'];
        const branch = formData.branch || publisherBankAccount['branch_name'];
        const provincebank = formData.Province || publisherBankAccount['province_id'];

        if (form.checkValidity() === true) {
            setAlertErrorError(false);
            event.preventDefault();
            event.stopPropagation();

            editBankAccount(bankID, accountName, accountNumber, accountType, branch, provincebank).then(handlePublisherDetailResponse)
        } else {
            setAlertErrorError(true);
        }

        setValidated(true);
    }


    const handlePublisherDetailResponse = async (response) => {
        // console.log(response.data);
        window.location.reload();
    }



    const handleChange = (event) => {

        const { name, value } = event.target || '';
        setFormData((prevData) => ({ ...prevData, [name]: value }));


        if (formData.newPassword != value) {
            setIdConfirmError('Error !! confirm Password');
        } else {
            setIdConfirmError('');
        }

        if (name == 'phoneNumber') {
            if (!isNumeric(value)) {
                setphoneNumberError('Please enter numbers only.');
            } else if (!isLength(value, { min: 10, max: 10 })) {
                setphoneNumberError('Please enter 10 digits');
            } else {
                setphoneNumberError('');
            }
        }

    };




    const ViewFinancial = (periodDate = '', DateView = '') => {
        // console.log('periodDate : '+periodDate);
        // console.log('DateView : '+DateView);
        navigate('/ViewFinancial', {
            state: {
                periodDate: periodDate,
                DateView: DateView
            },
            // replace: true 
        });
    };


    const handleDownload = (periodDateFinancual = "") => {
        viewFinancial(periodDateFinancual).then(response => {
       
            exportReportCSV(response.data['data']);
        });

    }



    useEffect(() => {
        // ทำสิ่งที่คุณต้องการทำเมื่อ selectedMonths เปลี่ยน
        // console.log('Selected months:', selectedMonths);
        showgetPublisherDetail();
        showBank();
        showgetBankAccount();
        showProvince();

    }, [selectedMonths]);

    const handleCheckboxChange = (month) => {

        // ถ้าปีปัจจุบันเท่ากับปี่ที่เลือก ต้องดูว่าเดือนที่เลือกน้อยกว่าหรือเท่ากับเดืิอนปัจจุบันหรือไม่  แต่ถ้าปีที่เลือกเป็นปีก่อนหน้า ก็ให้เลือกได้ทุกเดือน
        if ((currentYear == withHoldingTaxYear && month <= currentMonth) || currentYear != withHoldingTaxYear) {
            // สามารถเลือก checkbox ได้
            setSelectedMonths((prevMonths) => {
                if (prevMonths.includes(month)) {
                    // ถ้าเดือนนี้เคยถูกเลือกไว้แล้ว ให้นำออก
                    return prevMonths.filter((m) => m !== month);
                } else {
                    // ถ้าเดือนนี้ยังไม่ถูกเลือก ให้นำเข้า
                    return [...prevMonths, month];
                }
            });
        }
        // ถ้าเดือนยังไม่ถึง ให้ไม่ทำอะไร
    };

    const renderCheckbox = (month) => (
        <Form.Check
            key={month}
            inline
            label={moment().month(month - 1).format('MMM')}
            type='checkbox'
            onChange={() => handleCheckboxChange(month)}
            checked={selectedMonths.includes(month)}
            disabled={month > currentMonth && currentYear == withHoldingTaxYear}
        />
    );



    useEffect(() => {
        paymentData().then(response => {
            setDataPayment(response.data);
            // console.log(response.data);
        });
    }, []);

    const handleSelect = (key) => {
        setActiveKey(key);
    };

    return (
        (isPublisherLevel()) ?
        <div >
            <Head />
            <Row className='row-navbar'>
                <NavBar />
                <Col className='row-context' >
                    <main>

                        <div className='box-context'>
                            <span className='title-text'>Financial</span>
                            <p className='context-text'>All of your financial, bank account and view sale report and payment data.</p>

                            <div className='box-table'>
                                <Card className='card-box'>
                                    <Card.Body>
                                        <Tabs
                                            id="fill-tab-example"
                                            className="tabs-box"
                                            activeKey={activeKey}
                                            onSelect={handleSelect}
                                            fill>

                                            <Tab className='tab-text' eventKey="home" title="Payment">
                                                <div className='content-box-inner'>
                                                    <div className='box-context'>
                                                        <Row>
                                                            <div className='col-md-12' style={{ marginBottom: '20px' }}>
                                                                <Row>
                                                                    <span className='col-md-12 inner-title-text'>Payment</span>
                                                                    <div className='col-md-4 col-sm-5 subtitle-box'>
                                                                        
                                                                        <span className='subtitle-text'>Seller Balance</span>
                                                                        <br />
                                                                        <span className='context-text-p' >Update: &nbsp;
                                                                             {
                                                                                datagetPayment && datagetPayment[0] && datagetPayment[0]['created_date'] &&
                                                                                moment(localeString(datagetPayment[0]['created_date']), 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
                                                                            }
                                                                        </span>
                                                                        
                                                                        {datagetPayment && datagetPayment[0] && datagetPayment[0]['sumaccomulate_earned'] &&
                                                                            <p className='context-text-price'>{getCurrencyDisplay(convertToNumberWith2Decimal(datagetPayment[0]['sumaccomulate_earned']))}</p>
                                                                        }

                                                                    </div>

                                                                    <div className='col-md-8 col-sm-5' style={{ textAlign: 'center' }}>
                                                                        <div style={{float: 'right', paddingRight: '50px'}}>
                                                                        <span className='subtitle-text-right-align'>My Bank Account</span>
                                                                        <p className='context-text-p2' style={{float: 'left'}}>
                                                                            Account Name: {publisherBankAccount['account_name']}
                                                                            <br />
                                                                            Bank Name: {publisherBankAccount['bank_name']}
                                                                            <br />
                                                                            Account Number: {publisherBankAccount['account_number']}
                                                                        </p>
                                                                        </div>

                                                                    </div>
                                                                </Row>
                                                            </div>
                                                        </Row>
                                                        

                                                        <div className='box-table'>

                                                            <Table >
                                                                <thead className='thead-text'>
                                                                    <tr>
                                                                        <th><p>Payment Cycle </p></th>
                                                                        <th><p>Date Create</p></th>
                                                                        <th><p>Status</p></th>
                                                                        <th><p>Seller Amount (฿)</p></th>
                                                                        <th><p>Update On</p></th>
                                                                        <th><p>Action</p></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {Array.isArray(datagetPayment) ? (
                                                                        datagetPayment.map((option, index) => (

                                                                            <tr key={option["period_id"]}>
                                                                                <td style={{ verticalAlign: 'middle' }}>
                                                                                    <p className='td-text'>
                                                                                        {getDisplayDateShortFormat(option['period_date'])}
                                                                                    </p>
                                                                                </td>

                                                                                <td style={{ verticalAlign: 'middle' }}>
                                                                                    <p className='td-text'>
                                                                                        {getDisplayDate(option['created_date'],'DD/MM/YYYY')}
                                                                                    </p>
                                                                                </td>

                                                                                <td style={{ verticalAlign: 'middle' }}> <p className='td-text'> {option['payment_status']} </p> </td>

                                                                                <td style={{ verticalAlign: 'middle' }}>
                                                                                    <p className='td-text'>{convertToStringWith2Decimal(option['earned'])}</p>
                                                                                </td>

                                                                                <td style={{ verticalAlign: 'middle' }}>
                                                                                    <p className='td-text'>
                                                                                        {getDisplayDate(option['status_modified_date'],'DD/MM/YYYY')}
                                                                                    </p>
                                                                                </td>

                                                                                <td style={{ verticalAlign: 'middle' }}>
                                                                                    <a onClick={() => ViewFinancial(option['period_date'], getDisplayDateShortFormat(option['period_date']))}
                                                                                        className='normal-link link-button'>
                                                                                        View Details
                                                                                    </a>
                                                                                    <br></br>
                                                                                    <a onClick={() => handleDownload(option['period_date'].substring(0, 7))}
                                                                                        className='normal-link link-button'>
                                                                                        Download CSV
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    ) : (
                                                                        <Form.Label type="text"></Form.Label>
                                                                    )}
                                                                </tbody>
                                                            </Table>

                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>


                                            <Tab eventKey="Bank" title="Bank Account">
                                                <div className='content-box-inner'>
                                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>

                                                        <Form.Group as={Row} className="mb-3" controlId="">
                                                            <Form.Label column sm="4">
                                                                Bank <span className='star'>*</span>
                                                            </Form.Label>
                                                            <Col sm="6">
                                                                <Form.Select required value={formData.bank || publisherBankAccount['bank_id']} name="bank" onChange={handleChange} >
                                                                    <option>Please Select</option>
                                                                    {optionsBank.map((option) => (
                                                                        <option key={option.bank_id} value={option.bank_id}>
                                                                            {option.bank_name}
                                                                        </option>
                                                                    ))}

                                                                </Form.Select>
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} className="mb-3" controlId="">
                                                            <Form.Label column sm="4">
                                                                Account Number <span className='star'>*</span>
                                                            </Form.Label>
                                                            <Col sm="6">
                                                                <Form.Control type="text" maxLength={10} pattern="[0-9]{10}" placeholder="" isInvalid={!!accountNumberError} defaultValue={publisherBankAccount['account_number']} name="accountNumber" onChange={handleChange} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {accountNumberError}
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} className="mb-3" controlId="">
                                                            <Form.Label column sm="4">
                                                                Account Name <span className='star'>*</span>
                                                            </Form.Label>
                                                            <Col sm="6">
                                                                <Form.Control required type="text" maxLength={100} placeholder="" defaultValue={publisherBankAccount['account_name']} name="accountName" onChange={handleChange} />
                                                            </Col>
                                                        </Form.Group>




                                                        <Form.Group as={Row} className="mb-3" controlId="">
                                                            <Form.Label column sm="4">
                                                                Account Type <span className='star'>*</span>
                                                            </Form.Label>
                                                            <Col sm="6">
                                                                <Form.Select required value={formData.accountType || publisherBankAccount['account_type_id']} name="accountType" onChange={handleChange} >
                                                                    <option>Please Select</option>
                                                                    {optionsgetBankAccount.map((option) => (
                                                                        <option key={option.account_type_id} value={option.account_type_id}>
                                                                            {option.account_type_name}
                                                                        </option>
                                                                    ))}
                                                                </Form.Select>
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} className="mb-3" controlId="">
                                                            <Form.Label column sm="4">
                                                                Branch <span className='star'>*</span>
                                                            </Form.Label>
                                                            <Col sm="6">
                                                                <Form.Control required type="text" maxLength={100} placeholder="" defaultValue={publisherBankAccount['branch_name']} name="branch" onChange={handleChange} />
                                                            </Col>
                                                        </Form.Group>


                                                        <Form.Group as={Row} className="mb-3" controlId="">
                                                            <Form.Label column sm="4">
                                                                Province<span className='star'>*</span>
                                                            </Form.Label>
                                                            <Col sm="6">
                                                                <Form.Select required value={formData.Province || publisherBankAccount['province_id']} name="Province" onChange={handleChange} >
                                                                    <option>Please Select</option>
                                                                    {optionsProvince3.map((option) => (
                                                                        <option key={option.province_id} value={option.province_id}>
                                                                            {option.province_name}
                                                                        </option>
                                                                    ))}
                                                                </Form.Select>
                                                            </Col>
                                                        </Form.Group>

                                                        <br></br>
                                                        <center>
                                                            <Button className='button-form-save button-main button-orange' type="submit">Save</Button>
                                                        </center>

                                                    </Form>
                                                </div>

                                            </Tab>


                                            <Tab eventKey="Withholding" title="Withholding Tax Certificate">
                                                <div className='content-box-inner'>
                                                    <p className='context-text'>
                                                        Please select the month you would like to request tax documents.

                                                        After you submit your request, we will send you the documents by email within 30 working days
                                                    </p>

                                                    <div className='div-year'>

                                                        <div className='col-md-12 ' >

                                                            <div className='content-box-inner'>
                                                                <Form onSubmit={handleSubmitWithHoldingRequest}>

                                                                    <Form.Group as={Row} className="mb-3" controlId="">
                                                                        <Form.Label column sm="4">
                                                                            Email
                                                                        </Form.Label>
                                                                        <Col sm="5">
                                                                            <Form.Control type="text" value={userEmail} disabled placeholder="" />
                                                                        </Col>
                                                                    </Form.Group>

                                                                    <Form.Group as={Row} className="mb-3" controlId="">
                                                                        <Form.Label column sm="4">
                                                                            Year
                                                                        </Form.Label>
                                                                        <Col sm="5">
                                                                            <Form.Select required value={withHoldingTaxYear} name="withholdingTaxYear" onChange={handleChangeWithHoldingTaxYear}>
                                                                                {years.map((year) => (
                                                                                    <option key={year} value={year}>
                                                                                        {year}
                                                                                    </option>
                                                                                ))}
                                                                            </Form.Select>
                                                                        </Col>
                                                                    </Form.Group>


                                                                    <Form.Group as={Row} className="mb-3" controlId="">
                                                                        <Form.Label column sm="4">
                                                                            Month
                                                                        </Form.Label>
                                                                        <Col sm="5">
                                                                            {[...Array(12).keys()].map((month) => renderCheckbox(month + 1))}
                                                                        </Col>
                                                                    </Form.Group>
                                                                    <br></br>

                                                                    <center>
                                                                        <Button className='button-form-save button-main button-orange' type="submit">Send Request</Button>
                                                                    </center>
                                                                </Form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>

                                        </Tabs>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>

                    </main>
                </Col>


            </Row>
        </div>
        : ''
    );
};

export default Financial;
