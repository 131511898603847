export const siteSetShowLoadingStatus = showLoadingStatus => ({
    type: 'SITE_SET_SHOW_LOADING_STATUS',
    showLoadingStatus
})

export const setCurrentManageBookPage = currentManageBookPage => ({
    type: 'CURRENT_MANAGE_BOOK_PAGE',
    currentManageBookPage
})

export const setCurrentBooksApprovalInProgressPage = currentBooksApprovalInProgressPage => ({
    type: 'CURRENT_BOOKS_APPROVAL_IN_PROGRESS_PAGE',
    currentBooksApprovalInProgressPage
})

export const setCurrentBooksApprovalReadyofSalePage = currentBooksApprovalReadyofSalePage => ({
    type: 'CURRENT_BOOKS_APPROVAL_READY_OF_SALE_PAGE',
    currentBooksApprovalReadyofSalePage
})



// เก็บค่าตำแหน่งที่อยู่หน้าเว็บ
// export const setCurrentScrollY = currentScrollY => ({
//     type: 'CURRENT_SCROLLY_BOOK_PAGE',
//     currentScrollY
// })