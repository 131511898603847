
import React, { useState, useEffect } from 'react';
import { Row, Col, InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import Head from '../../components/navbar/Head.js';
import NavBar from '../../components/navbar/NavBar.js';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import '../../api/index.js';
import '../../helper/ApiHelper.js'
import Button from 'react-bootstrap/Button';


import { getCategories, searchCategories } from '../../api/index.js'
import Icon_DotRed from '../../image/dot_red.png';
import Icon_Public from '../../image/dot_green.png';
import { useSelector } from 'react-redux';
const Categories = () => {

    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();
    const [formData, setFormData] = useState('');

    function showCategories() {
        getCategories().then(response => {
            setCategories(response.data);
        });
    }

    useEffect(() => {
        showCategories();
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target || '';
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        if (event.key === 'Enter') {
            // do whatever
            handleSearch();
        }
    }

    const handleSearch = () => {
        const dataKeyword = formData.keyword || '';
        const columnName = formData.columnName || 'name_en';
        searchCategories(dataKeyword, columnName).then(response => {
            setCategories(response.data);
        });
    }



    const editCategories = (id) => {
        navigate('/EditCategories', {
            state: {
                categoriesId: id
            },
            // replace: true
        });
        // window.location.reload();
    }

    const handleLinkClick = (url) => {
        // Replace the current URL
        navigate(url);
        
    };

    return (
        <div >
            <Head />
            <Row className='row-navbar'>
                <NavBar />
                <Col className='row-context' >
                    <main>
                        <div className='box-context'>
                            <div className='div-row-col'>
                                <Row sm={12}>
                                    <span className='title-text'>Categories</span>
                                    <p className='context-text'>Manage categories</p>

                                    <Col sm={12} md={5}>
                                        <Button onClick={() => handleLinkClick('/AddCategories')} className='button-action button-main button-orange' variant="primary" type="submit">+ Add Categories</Button>
                                    </Col>
                                    <Col sm={12} md={7}>
                                        <InputGroup>

                                            <Form.Control style={{ width: '150px' }} className='form-control-custom' name='keyword'
                                                onKeyUp={(e) => {
                                                    handleChange(e);

                                                }}
                                                placeholder="Search..." aria-label="Search..." />
                                            <Form.Select name='columnName' onChange={handleChange} className='form-control-custom2' style={{ width: '20px', borderBottomRightRadius: '5px', borderTopRightRadius: '5px' }} >

                                                <option value="name_en" >Category Name</option>
                                                <option value="status" >Status</option>

                                            </Form.Select>

                                            <div md={1} style={{ marginLeft: '10px' }}>

                                                <Button className='button-search button-main button-white' variant="primary" onClick={() => {
                                                            handleSearch();
                                                        }} >Search</Button>
                                            </div>
                                        </InputGroup>
                                    </Col>
                                </Row>

                            </div>

                            <div className='box-table'>
                                <Card className='card-box'>
                                    <Card.Body>
                                        <Col className='' >
                                            <main>
                                                <div className=''>
                                                    <div className=''>
                                                        <Table hover >
                                                            <thead className='thead-text'>
                                                                <tr>
                                                                    <th><p>No</p></th>
                                                                    <th><p>Category Name</p></th>
                                                                    <th><p>Match Titles</p></th>
                                                                    <th><p>Status</p></th>
                                                                    <th><p>Display Order</p></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {categories.map((option, index) => (

                                                                    <tr key={option.id} onClick={() => editCategories(option.id)} style={{cursor: 'pointer'}} title='Click to edit categories.'>
                                                                        <td>  <p className='td-text'>{index + 1} </p> </td>
                                                                        <td>  <p className='td-text'>{option.name_en} </p> </td>
                                                                        <td>  <p className='td-text'>{option.total} </p> </td>
                                                                        <td>  <p className='td-text'>
                                                                            {option.status === 'draft' && (
                                                                                <img src={Icon_DotRed} width='12px;' className='str_icon' />
                                                                            )}
                                                                            {option.status === 'publish' && (
                                                                                <img src={Icon_Public} width='12px;' className='str_icon' />
                                                                            )}
                                                                            {option.status}
                                                                        </p>
                                                                        </td>
                                                                        <td>  <p className='td-text'>{option.rank} </p> </td>
                                                                    </tr>
                                                                ))}

                                                            </tbody>
                                                        </Table>
                                                        {(categories.length < 1 && formData.keyword != null) ? 
                                                            <div className='search-no-result'><center><strong>No results for '{formData.keyword}'</strong></center></div>: ''
                                                        }

                                                    </div>
                                                </div>

                                            </main>
                                        </Col>

                                    </Card.Body>
                                </Card>
                            </div>
                        </div>

                    </main>
                </Col>


            </Row>
        </div >
    );
};

export default Categories;
